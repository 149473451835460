import Vue from 'vue'
import Breakpoints from '../vue/breakpoints'
import LazyLoad from "vanilla-lazyload";
import Swiper from 'swiper';
import MmenuLight from  '../../node_modules/mmenu-light/src/mmenu-light.js';
import ScrollOut from 'scroll-out';
import bodymovin from 'lottie-web';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
// import { DrawSVGPlugin } from "gsap/dist/DrawSVGPlugin";
import { MotionPathPlugin } from "gsap/dist/MotionPathPlugin";
// gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin, MotionPathPlugin);
gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);


// Intiate Vuejs instance
var app = new Vue({
    el: '#app',
    delimiters: ["${","}"],
    components: {
        Breakpoints,
    }
})

// Scripts to fire once dom has loaded
document.addEventListener("DOMContentLoaded", () => {
    const lottieLogo = document.getElementById('lottieLogo');
    lottieLogo.addEventListener('mouseenter', function(e) { 
        e.preventDefault();
        animation.goToAndPlay(1, true);
    });

    const animation = bodymovin.loadAnimation({
        container: lottieLogo,
        path: '/lottie/Logo_V2.json',
        renderer: 'svg',
        loop: false,
        autoplay: false,
    });

    const lottieHeader = document.getElementById('lottieHeader');
    const animationHeader = bodymovin.loadAnimation({
        container: lottieHeader,
        path: '/lottie/Header_V1.json',
        renderer: 'svg',
        loop: true,
        autoplay: true,
    });

    const lottieBrain = document.getElementById('lottieBrain');
    const animationBrain = bodymovin.loadAnimation({
        container: lottieBrain,
        path: '/lottie/2_V1.json',
        renderer: 'svg',
        loop: false,
        autoplay: false,
    });

    const lottieStar = document.getElementById('lottieStar');
    const animationStar = bodymovin.loadAnimation({
        container: lottieStar,
        path: '/lottie/3_V1.json',
        renderer: 'svg',
        loop: false,
        autoplay: false,
    });

    const lottieLoop = document.getElementById('lottieLoop');
    const animationLoop = bodymovin.loadAnimation({
        container: lottieLoop, 
        path: '/lottie/1_V1.json',
        renderer: 'svg', 
        loop: false,
        autoplay: false,
    });

    // play lottie when it enters the view port //
    var elementLoop = document.getElementById('lottieLoop');
    var elementStar = document.getElementById('lottieStar');
    var elementBrain = document.getElementById('lottieBrain');
    var windowHeight;

    if(elementLoop){
        function init() {
            windowHeight = window.innerHeight;
        }

        function checkPosition() {
            var positionFromTopLoop = elementLoop.getBoundingClientRect().top;
            if (positionFromTopLoop - windowHeight <= 0) {
                animationLoop.play();
            }
        }

        window.addEventListener('scroll', checkPosition);
        window.addEventListener('resize', init);
   
        init();
        checkPosition();
    }

    if(elementStar){
        function init() {
            windowHeight = window.innerHeight;
        }

        function checkPosition() {
            var positionFromTopStar = elementStar.getBoundingClientRect().top;
            if (positionFromTopStar - windowHeight <= 0) {
                animationStar.play();
            }
        }

        window.addEventListener('scroll', checkPosition);
        window.addEventListener('resize', init);
   
        init();
        checkPosition();
    }

    if(elementBrain){

        function init() {
            windowHeight = window.innerHeight;
        }

        function checkPosition() {
            var positionFromTopBrain = elementBrain.getBoundingClientRect().top;
            if (positionFromTopBrain - windowHeight <= 0) {
                animationBrain.play();
            }
        }
   
        window.addEventListener('scroll', checkPosition);
        window.addEventListener('resize', init);
   
        init();
        checkPosition();
    }
    ////
    
    var frameName = new ds07o6pcmkorn({
        openElementId: "#shielded-logo"
    });
    frameName.init();

    fadeInPage();

    // Page transition functionality
    function fadeInPage() {
        if (!window.AnimationEvent) { return; }
        var fader = document.getElementById('fader');
        fader.classList.add('fade-out');
    }

    if (!window.AnimationEvent) { return; }

    var anchors = document.querySelectorAll('.fade-btn');

    for (var idx=0; idx<anchors.length; idx+=1) {
        if (anchors[idx].hostname !== window.location.hostname) {
            continue;
        }
        anchors[idx].addEventListener('click', function(event) {
                var fader = document.getElementById('fader'),
                    anchor = event.currentTarget;
                var listener = function() {
                    window.location = anchor.href;
                    fader.removeEventListener('animationend', listener);
                };
                fader.addEventListener('animationend', listener);
                event.preventDefault();
                fader.classList.add('fade-in');
        });
    }

    window.addEventListener('pageshow', function (event) {
        console.log(event);

        if (!event.persisted) {
            return;
        }
        var fader = document.getElementById('fader');
        fader.classList.remove('fade-in');
    });

    // Mobile menu init // Sync search and menu open
    const menu = new MmenuLight( document.querySelector( "#mobile-menu" ), "(max-width: 1024px)" );
    const navigator = menu.navigation();
    const drawer = menu.offcanvas();

    // Open Menu
    const navTrigger = document.querySelector('.js-open-nav');
    const projectWrapper = document.getElementById('app');
    const menuLabel = document.getElementById('menuLabel');

    // Toggle Menu
    bindEvent(navTrigger, "click", function() {
        this.classList.toggle('is-active');
        projectWrapper.classList.toggle('js-is-open');

        if (menuLabel.innerHTML === "Menu") {
        menuLabel.innerHTML = "Close";
        } else {
            menuLabel.innerHTML = "Menu";
        }

        if (this.classList.contains('is-active')) {
            drawer.open();
        } else {
            drawer.close();
        }

    }, false);

    // Lazyload
    var lazy = new LazyLoad({
        elements_selector: ".lazy"
    });

    // Recommended swiper
    const storySwiper = document.getElementById('storySwiper');

    if(storySwiper){

        const sliderBreakpoint = window.matchMedia( '(min-width:1024px)' );
        let storySwiper;

        const breakpointChecker = function() {

        // if larger viewport and multi-row layout needed
        if ( sliderBreakpoint.matches === true ) {
            if ( storySwiper !== undefined ) storySwiper.destroy( true, true );
                return;
            } else if ( sliderBreakpoint.matches === false ) {
                return enableSwiper();
            }
        };

        const enableSwiper = function() {
            storySwiper = new Swiper ('#storySwiper', {
                grabCursor: true,
                freeMode: true,
                slidesPerView: 'auto',

                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
            });
        };

        //keep an eye on viewport size changes
        sliderBreakpoint.addListener(breakpointChecker);
        breakpointChecker();
    }

    // OVERLAY FUNCTIONALITY
    const overlayTriggers = document.querySelectorAll('.js-open-overlay'),
    overlayTeam = document.getElementById('overlay-team'),
    // overlayVideo = document.getElementById('overlay-video'),
    // const overlayVideoTriggers = document.querySelectorAll('.js-open-video'),

    close = document.getElementById('js-close-modal'),
    app = document.getElementById('app'),
    modal = document.querySelector('.js-modal');

    if(overlayTriggers.length){ 
        for (var i = 0; i < overlayTriggers.length; i++) {
            overlayTriggers[i].addEventListener('click',  function(e) {
                e.stopPropagation()
                overlayTeam.classList.add('show');
                
                document.getElementById("member-title").innerHTML = this.dataset.memberTitle ?? null;
                document.getElementById("member-pos").innerHTML = this.dataset.memberPos ?? null; 
                document.getElementById("member-desc").innerHTML = this.dataset.memberDesc ?? null;
                
                const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
                app.style.position = 'fixed';
                app.style.top = `-${scrollY}`;
            });
        }
        close.addEventListener('click', function(){
            overlayTeam.classList.remove("show");
            const scrollY = app.style.top;
            app.style.position = '';
            app.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        });

        //Closes the modal when the document is clicked
        overlayTeam.addEventListener('click', () => {
            overlayTeam.classList.remove('show');
            const scrollY = app.style.top;
            app.style.position = '';
            app.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        });

        //Prevents the click event from reaching the document
        app.addEventListener('click', (e) => {
            e.stopPropagation();
        });

        modal.addEventListener('click', (e) => {
            e.stopPropagation();
        });

        window.addEventListener('scroll', () => {
            document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
        });
    }
    const overlayVideoTriggers = document.querySelectorAll('.js-open-video'),
    overlayVideo = document.getElementById('overlay-video');

    if(overlayVideoTriggers.length){ 
        for (var i = 0; i < overlayVideoTriggers.length; i++) {
            overlayVideoTriggers[i].addEventListener('click',  function(e) {
                e.stopPropagation()
                overlayVideo.classList.add('show');
                
                const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
                app.style.position = 'fixed';
                app.style.top = `-${scrollY}`;
            });
        }
        close.addEventListener('click', function(){
            overlayVideo.classList.remove("show");
            const scrollY = app.style.top;
            app.style.position = '';
            app.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        });

        //Closes the modal when the document is clicked
        overlayVideo.addEventListener('click', () => {
            overlayVideo.classList.remove('show');
            const scrollY = app.style.top;
            app.style.position = '';
            app.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        });

        //Prevents the click event from reaching the document
        app.addEventListener('click', (e) => {
            e.stopPropagation();
        });

        modal.addEventListener('click', (e) => {
            e.stopPropagation();
        });

        window.addEventListener('scroll', () => {
            document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
        });
    }

    // ACCORDIONS
    const accordionItems = document.querySelectorAll('[data-accordion-item]');
    const accordionContentPanes = document.querySelectorAll('[data-accordion-content]');
    if (accordionItems.length) {
        accordionItems.forEach(function(accordion) {
            const accordionTitleRow = accordion.firstElementChild;
            bindEvent(accordionTitleRow, 'click', toggleAccordion);
        });
    }

    function toggleAccordion(e) {
    
        if(e.target.parentElement.classList.contains('accordion-item--active')) {
            e.target.parentElement.classList.remove('accordion-item--active');
            return;
        }
        accordionContentPanes.forEach(function(content) {
            if (content.previousElementSibling === e.target) {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.classList.remove('accordion-item--active');
                }
                content.parentElement.classList.add('accordion-item--active');
            } else {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.parentElement.classList.remove('accordion-item--active');
                }
                content.classList.add('accordion-item--active');
            }
        });
    }

    function bindEvent(el, eventName, eventHandler) {
        if (el.addEventListener){
            el.addEventListener(eventName, eventHandler, false);
        } else if (el.attachEvent){
            el.attachEvent('on'+eventName, eventHandler);
        }
    }

    ScrollOut({
        once: true,
    });

    let pathA = gsap.timeline({defaults: {duration: 2},
        scrollTrigger: {
            trigger: "#path-a",
            scrub: true,
            start: "top top"
        }
    })
    pathA.from(".path-a-line", {drawSVG: 0}, 0)

    let pathB = gsap.timeline({defaults: {duration: 2},
        scrollTrigger: {
            trigger: "#path-b",
            scrub: true,
            start: "top top"
        }
    })
    pathB.from(".path-b-line", {drawSVG: 0}, 0)

    let pathC = gsap.timeline({defaults: {duration: 2},
        scrollTrigger: {
            trigger: "#path-c",
            scrub: true,
            start: "top top"
        }
    })
    pathC.from(".path-c-line", {drawSVG: 0}, 0)

    let pathD = gsap.timeline({defaults: {duration: 2},
        scrollTrigger: {
            trigger: "#path-d",
            scrub: true,
            start: "top top"
        }
    })
    pathD.from(".path-d-line", {drawSVG: 0}, 0)

    let pathE = gsap.timeline({defaults: {duration: 2},
        scrollTrigger: {
            trigger: "#path-e",
            scrub: true,
            start: "top top"
        }
    })
    pathE.from(".path-e-line", {drawSVG: 0}, 0)

    let pathF = gsap.timeline({defaults: {duration: 2},
        scrollTrigger: {
            trigger: "#path-f",
            scrub: true,
            start: "top top"
        }
    })
    pathF.from(".path-f-line", {drawSVG: 0}, 0)

    let pathG = gsap.timeline({defaults: {duration: 2},
        scrollTrigger: {
            trigger: "#path-g",
            scrub: true,
            start: "top top"
        }
    })
    pathG.from(".path-g-line", {drawSVG: 0}, 0)

    let pathH = gsap.timeline({defaults: {duration: 2},
        scrollTrigger: {
            trigger: "#path-h",
            scrub: true,
            start: "top top"
        }
    })
    pathH.from(".path-h-line", {drawSVG: 0}, 0)

    let pathI = gsap.timeline({defaults: {duration: 2},
        scrollTrigger: {
            trigger: "#path-i",
            scrub: true,
            start: "top top"
        }
    })
    pathI.from(".path-i-line", {drawSVG: 0}, 0)

    let pathJ = gsap.timeline({defaults: {duration: 2},
        scrollTrigger: {
            trigger: "#path-j",
            scrub: true,
            start: "top top"
        }
    })
    pathJ.from(".path-j-line", {drawSVG: 0}, 0)

    let pathK = gsap.timeline({defaults: {duration: 2},
        scrollTrigger: {
            trigger: "#path-k",
            scrub: true,
            start: "top top"
        }
    })
    pathK.fromTo(".path-k-line", {drawSVG:"100% 100%"}, {drawSVG:"0% 100% "})

    const donationIntBtns = document.querySelectorAll('.donation-interval-btn');
    const donationBtns = document.querySelectorAll('.donation-amount-btn');
    const donationDescription = document.getElementById('donation-description');
    const donationAmtInput = document.getElementById('donation-amount-input');
    const donationBtn = document.getElementById('donate-btn');

    if(donationIntBtns.length){ 
        for (var i = 0; i < donationIntBtns.length; i++) {
            donationIntBtns[i].addEventListener('click',  function(e) {
                checkSiblings(donationIntBtns[i])
                if(this.classList.contains('primary')){
                    this.classList.remove('primary');
                    this.classList.add('donation-interval-selected');
                }
            });
        }
        function checkSiblings($currentNode){
            for (var i = 0; i < donationIntBtns.length; i++) {
                if($currentNode != donationIntBtns[i]){
                    donationIntBtns[i].classList.add('primary')
                    donationIntBtns[i].classList.remove('donation-interval-selected')
                }
            }    
        }
    }
    
    if(donationBtns.length){ 
        donationDescription.innerText = donationBtns[2].getAttribute('data-item-description');
        for (var i = 0; i < donationBtns.length; i++) {
            donationBtns[i].addEventListener('click',  function(e) {
                checkSiblings(donationBtns[i])
                if(this.classList.contains('primary')){
                    this.classList.remove('primary');
                    this.classList.add('donation-amount-selected');
                    donationDescription.innerText = this.getAttribute('data-item-description');
                }
                if(this.id == "donation_other"){
                    donationAmtInput.classList.remove('hidden');
                }else{
                    donationAmtInput.classList.add('hidden');
                    donationAmtInput.value = "";
                }
            });
        }
        function checkSiblings($currentNode){
            for (var i = 0; i < donationBtns.length; i++) {
                if($currentNode != donationBtns[i]){
                    donationBtns[i].classList.add('primary')
                    donationBtns[i].classList.remove('donation-amount-selected')
                }
            }    
        }
    }
    const swiper = new Swiper('.product-img-swiper', {
        // Optional parameters
        direction: 'horizontal',
        loop: false,
        // If we need pagination
        pagination: {
          el: '.swiper-pagination',
          type: "fraction"
        },
        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        // And if we need scrollbar
        scrollbar: {
          el: '.swiper-scrollbar',
        },
      });

      if(donationBtn){
        document.addEventListener('snipcart.ready', function() {
            Snipcart.api.session.setLanguage('en', {
                "confirmation": {
                    "thank_you_for_your_order": "Thank you for your donation",
                    "async_confirmation_notice": "Your donation has been placed and is currently processing. You will receive a confirmation shortly."
                },
            });
        });   
        donationBtn.addEventListener('click',  function(e) {
            removeDonationButtonAttribute()
            var donationIntervalSelected = document.querySelector('.donation-interval-selected');
            var donationAmountSelected = document.querySelector('.donation-amount-selected');
            if(donationAmountSelected.id == "donation_other"){
                var donationAmount = document.getElementById('donation-amount-input').value;
            }else{
                var donationAmount = donationAmountSelected.value;
            }
            if(donationAmount == "" || donationAmount < 10){
                removeDonationButtonAttribute()
                alert("Please select or enter an amount of at least 10!");
            }else{
                donationBtn.setAttribute('class', "mb-0 button primary snipcart-add-item");
                donationBtn.setAttribute('data-item-name', "Donation of NZ$ "+donationAmount);
                donationBtn.setAttribute('data-item-shippable', "false");
                donationBtn.setAttribute('data-item-taxable', "false");
                donationBtn.setAttribute('data-item-custom1-name', "Donation");
                donationBtn.setAttribute('data-item-custom1-type', "hidden");
                donationBtn.setAttribute('data-item-custom1-value', "true");
                donationBtn.setAttribute('data-item-categories', "Donations");
                if(donationIntervalSelected.value == "once"){
                    var donationID = "once";
                    donationBtn.setAttribute('data-item-id', "donation");
                    donationBtn.setAttribute('data-item-price',donationAmount);
                    donationBtn.setAttribute('data-item-max-quantity', "1");
                    donationBtn.setAttribute('data-item-description', "Donation to Laura Fergusson Foundation");
                    donationBtn.setAttribute('data-item-url',"/giving/donate-online?amount="+donationAmount+"&plan="+donationIntervalSelected.value+"&id="+donationID);
                }else{
                    var donationID = donationAmountSelected.id;
                    donationBtn.setAttribute('data-item-id', donationAmountSelected.id);
                    donationBtn.setAttribute('data-item-price', donationAmount);
                    donationBtn.setAttribute('data-item-selected-plan', donationIntervalSelected.value);
                    if(donationIntervalSelected.value == "yearly-donation" || donationIntervalSelected.value == "monthly-donation"){
                        donationBtn.setAttribute('data-item-description', getPlanDescription(donationIntervalSelected.value) + " to Laura Fergusson Foundation");
                        if(donationIntervalSelected.value == "monthly-donation"){
                            donationBtn.setAttribute('data-plan1-id', "monthly-donation");
                            donationBtn.setAttribute('data-plan1-name', "Monthly Donation");
                            donationBtn.setAttribute('data-plan1-interval', "1");
                            donationBtn.setAttribute('data-item-plan1-price', donationAmount);
                            donationBtn.setAttribute('data-plan1-frequency', "Monthly");
                        }
                        if(donationIntervalSelected.value == "yearly-donation"){
                            donationBtn.setAttribute('data-plan2-id', "yearly-donation");
                            donationBtn.setAttribute('data-plan2-name', "Yearly Donation");
                            donationBtn.setAttribute('data-plan2-interval', "1");
                            donationBtn.setAttribute('data-item-plan2-price', donationAmount);
                            donationBtn.setAttribute('data-plan2-frequency', "Yearly");
                        }
                    }
                    donationBtn.setAttribute('data-item-url',"/giving/donate-online?amount="+donationAmount+"&plan="+donationIntervalSelected.value+"&id="+donationID+"&selected-plan="+donationIntervalSelected.value);
                }
            }        
        });
    }

    document.addEventListener('snipcart.ready', () => {
        var newDonationAmount = null;
        Snipcart.events.on('item.adding', (parsedCartItem) => {
            // console.log(parsedCartItem);
            newDonationAmount = parsedCartItem.price;
        });
        function updateCartItemPrice(cartItem) {
            try {
                Snipcart.api.cart.items.update({
                  uniqueId: cartItem.uniqueId,
                  name: cartItem.name,
                  price: cartItem.newDonationAmount,
                  description:cartItem.description,
                  url: cartItem.url,
                });
            } catch (error) {
                console.log(error)
            } 
        }
        // You can safely use window.Snipcart here
        Snipcart.events.on('item.updated', (cartItem) => {
            if(cartItem.price != newDonationAmount){
                updateCartItemPrice(cartItem)
            }
            if(cartItem.selectedPlanId == "yearly-donation" || cartItem.selectedPlanId == "monthly-donation"){
                cartItem.description = getPlanDescription(cartItem.selectedPlanId)+ " to Laura Fergusson Foundation";
                document.getElementById(cartItem.selectedPlanId).click();
            }
        });
        Snipcart.events.on('item.removed', (cartItem) => {
            removeDonationButtonAttribute()
        });
    });
    function getPlanDescription(selectedPlan) {
        var selectedPlan = selectedPlan.replace('-', ' ');
        selectedPlan = selectedPlan.charAt(0).toUpperCase() + selectedPlan.slice(1);
        selectedPlan = selectedPlan;
        return selectedPlan;
    }
    function removeDonationButtonAttribute() {
        if(donationBtn){
            while(donationBtn.attributes.length > 0) {
                donationBtn.removeAttributeNode(donationBtn.attributes[0]);  
            }
            donationBtn.setAttribute('id', "donate-btn");
            donationBtn.setAttribute('class', "mb-0 button primary");
        }
    }
})