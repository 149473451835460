<template>
    <div class="breakpoints">{{window.width }}px</div>
</template>

<script>
module.exports = {
    data: function () {
        return {
            message: 'Hello Vue!',
            window: {
                width: 0
            }
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        handleResize() {
            this.window.width = window.innerWidth;
        }
    }
}
</script>

<style scoped>
.breakpoints {
    font-family: -apple-system, BlinkMacSystemFont,"Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
    position: fixed;
    bottom: 25px;
    right: 25px;
    background-color: #6166dc;
    color: #f1f1f1;
    font-size: 10px;
    text-transform: uppercase;
    padding: 5px 10px;
    border-radius: 6px;
    text-align: center;
    z-index: 500;
    box-shadow: 0 4px 8px -1px rgba(0,0,0,.3),0 2px 6px -1px rgba(0,0,0,.07);
}
</style>